/*Term of Use css start*/
body  .bg-tou
{
	background-color: #0A51A1 !important;
}
.tou-title
{
	color: #F8B322;
	font-size: 21px;
	font-family: lora;
	line-height: 29.4px;
}
.tou-content
{
	color: #F3F3F3;
	font-size: 15px;
	line-height: 21px;
}
/*Term of Use css end*/
