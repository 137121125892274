body {
  background-color: #00254f !important;
  font-family: "Poppins", sans-serif;
  overflow-y: visible !important;
  overflow-x: hidden !important;
  width: 100% !important;
  
}
main.layout-main{
  padding-bottom: 2em;
}
/*login css start*/
.logo {
  margin-top: 153.68px;
}
a {
  text-decoration: none !important;
}
ul
{
  list-style: none !important;
}
.react-datepicker-wrapper.hidden-datepicker {
  /* display: none !important; */
  visibility: hidden;
  width: 0px;
}
.title {
  font-family: lora;
  font-size: 21px;
  font-weight: 500;
  line-height: 29.4px;
  letter-spacing: 0.15px;
}
.title-margin {
  padding: 32px 0px 0px !important;
}

.event-margin{
  margin-top: 12px !important;
}
.event-margin-right-left{
  padding-left: 16px !important;
  padding-right: 16px !important;
}
.no-data-margin {
  padding: 0px;
}
.fs-family-lora {
  font-family: lora !important;
}
.tx-font-sansserif
{
    font-family:sans-serif;
}
.google-icon img {
  border: 2px solid #4285f4;
  border-radius: 4px 0px 0px 4px;
  margin-right: -4px;
  padding: 5px;
}
.gsi-material-button {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -webkit-appearance: none;
  background-color: #003978;
  border: none;
  border-radius: 0px 4px 4px 0px;
  color: #fff;
  font-family: "Roboto", arial, sans-serif;
  font-size: 14px;
  height: 49px;
  letter-spacing: 0.25px;
  outline: none;
  overflow: hidden;
  padding: 0 12px;
  position: relative;
  text-align: left;
  transition: background-color 0.218s, border-color 0.218s, box-shadow 0.218s;
  vertical-align: middle;
  white-space: nowrap;
  width: 250px;
}

.gsi-material-button .gsi-material-button-icon {
  height: 20px;
  margin-right: 12px;
  min-width: 20px;
  width: 20px;
}

.gsi-material-button .gsi-material-button-contents {
  -webkit-flex-grow: 1;
  flex-grow: 1;
  font-size: 15px;
  font-family: poppins;
  font-weight: 300;
  letter-spacing: 0.5px;
}

.gsi-material-button .gsi-material-button-state {
  -webkit-transition: opacity 0.218s;
  transition: opacity 0.218s;
  bottom: 0;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.gsi-material-button:disabled {
  cursor: default;
  background-color: #ffffff61;
  border-color: #1f1f1f1f;
}

.gsi-material-button:disabled .gsi-material-button-contents {
  opacity: 38%;
}

.gsi-material-button:disabled .gsi-material-button-icon {
  opacity: 38%;
}

.gsi-material-button:not(:disabled):active .gsi-material-button-state,
.gsi-material-button:not(:disabled):focus .gsi-material-button-state {
  background-color: #303030;
  opacity: 50%;
}

.gsi-material-button:not(:disabled):hover {
  -webkit-box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 50),
    0 1px 3px 1px rgba(60, 64, 67, 0.15);
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 50),
    0 1px 3px 1px rgba(60, 64, 67, 0.15);
}

.gsi-material-button:not(:disabled):hover .gsi-material-button-state {
  background-color: #303030;
  opacity: 58%;
}
.checkbox {
  font-size: 14px;
  letter-spacing: 0.4px;
  font-weight: 400;
}
input[type="checkbox" i] {
  width: 15px;
  height: 15px;
  accent-color: #003978;
}
/*login css end*/

/*create resident form css start*/
.bg-primery {
  background: #003978 !important;
}
.bg-primery-2 {
  background: #00254f  !important;
}
 
.sidebar-li-bg{
  background: #184b85 !important;
}
.notification-bg a
{
  background: #184b85 !important;
}
.bg-primery-update{
  background-color: #00254f;
}

.bg-search {
  background: #003978 !important;
}
.mein-menu ul {
  text-decoration: none;
}
.mein-menu ul li {
  border-bottom: 1px solid #9d9d9d !important;
  --bs-nav-link-padding-y: 20px;
  width: 100%;
}
.mein-menu ul li:hover {
  background: #00254f !important;
}
.mein-menu ul li a {
  --bs-nav-link-padding-x: 10px;
  font-size: 14px;
}
.mein-menu ul li a.active {
  background: #00254f !important;
}
.search-btn {
  margin-right: -40px;
}
button.blk-btn {
  background: #fff;
  color: #003978;
  padding: 10px 30px;
  border: 1px solid #cccccc;
  border-radius: 30px;
  margin-right: 2px;
}
button.blk-btn a {
  /*font-family: Poppins;*/
  font-weight: 500 !important;
  color: #fff;
}
button.blk-btn:hover {
  background: #003978;
  color: #ffff;
}
button.blk-btn a:hover {
  color: #ffff;
}
button.blk-btn.active {
  background: #003978;
  color: #ffff !important;
}

button.storey-btn {
  background: #fff;
  color: #1f6fca;
  /* width: 50px;
  height: 50px; */
  min-width: 50px;
  min-height: 50px;
  text-align: center;
  border: 1px solid #cccccc;
  border-radius: 50px;
  margin-right: 2px;
  margin-top: 4px;
}
button.storey-btn a {
  /*font-family: Poppins;*/
  font-weight: 500 !important;
  color: #fff;
}
button.storey-btn:hover {
  background: #003978;
  color: #ffff;
}
button.storey-btn a:hover {
  color: #ffff;
}
button.storey-btn.active {
  background: #003978 !important;
  color: #ffff !important;
}
button.storey-btn.disabled {
  background: #ffffff;
  color: #003978 !important;
}
.create-btn {
  background: #003978;
  color: #fff;
  width: 205px;
  height: 48px;
  border: 1px solid #cccccc;
  border-radius: 30px;
}
.yes-btn {
  background: #003978;
  color: #fff;
  width: 205px;
  height: 48px;
  border: 1px solid #cccccc;
  border-radius: 30px;
}
.no-btn {
  background: #fff;
  color: #003978;
  width: 205px;
  height: 48px;
  border: 1px solid #cccccc;
  border-radius: 30px;
}
.reset-btn {
  background: grey;
  color: #fff;
  width: 205px;
  height: 48px;
  border: 1px solid #cccccc;
  border-radius: 30px;
}
.apply-btn {
  background: #003978;
  color: #fff;
  width: 205px;
  height: 48px;
  border: 1px solid #cccccc;
  border-radius: 30px;
}
.download-btn {
  background: #003978;
  color: #fff;
  width: 205px;
  height: 48px;
  border: 1px solid #cccccc;
  border-radius: 30px;
}

/*create resident form css end*/

/*footer menu mobile view css start*/
.footer-fixed footer {
  padding: env(safe-area-inset-top) env(safe-area-inset-right)
    env(safe-area-inset-bottom) env(safe-area-inset-left);
}

.navbar-fixed nav {
  padding: env(safe-area-inset-top) env(safe-area-inset-right)
    env(safe-area-inset-bottom) env(safe-area-inset-left);
}

.footer-fixed {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 999999;
  height: 70px;
}
footer {
  ul {
    &.justify {
      /*text-align: center;*/
      /*display: table;*/
      overflow: hidden;
      margin: 0 auto;
      padding: 0;
      text-decoration: none !important;
      list-style: none !important;
      li {
        padding: 20px;
        float: left;
        width: 25%;
      }
    }
  }
}
footer li a {
  font-size: 24px;
  color:  #B3B3B3;
}

footer li:hover {
  /* filter: invert(100%) brightness(100%); */
  filter:  brightness(0) invert(1);
  color: #fff;
}
footer li a p {
  font-size: 15px;
  color: #B3B3B3;
  margin: 0;
}
/*footer menu mobile view css end*/

/*form css start*/
.status_info-btn {
  background: #ffff;
  color: #000;
  line-height: 42px;
  width: 100%;
  border: 1px solid #cccccc;
  border-radius: 4px;
  padding: 0;
  margin: 2px;
  float: left;
}
button.status_info-btn:hover {
  background: white;
  color: #003978;
}
/* #003978 */
/* white  */

.radio-btn:hover,
.radio-btn.active {
  background: #003978 !important;
  border-color: #003978  !important;
  color:white   !important;
}

.radio-btn {
  background: white ;
  border-color: #003978;
  color: #003978 ;
  font-weight: bold;
}
.progress-bar-container {
  width: 100%;
  background-color: #f3f3f3;
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow: hidden;
  margin-top: 10px;
}

.progress-bar {
  height: 20px;
  background-color: #4caf50;
  text-align: center;
  color: white;
  line-height: 20px; /* Adjust this if the text is not centered */
}

button.status_info-btn a:hover {
  color: #003978;
}

/* Container for the input and label */
.form-group-resident {
  position: relative;
  margin-bottom: 1.5rem;
}

/* Styling the input field */
.form-group-resident input {
  width: 100%;
  padding: 1rem 0.75rem;
  font-size: 1rem;
  border: 1px solid #ccc;  
  border-radius: 5px !important;
  -webkit-border-radius: 5px !important;
  outline: none;
  background: transparent !important;
}
.form-group-resident input[type='range'] {
  width: 100%;
  padding: 12px 0 16px 0;
  font-size: 1rem;
  border: none;
  border-radius: 5px !important;
  -webkit-border-radius: 5px !important;
  outline: none;
  background: transparent !important;
}
/* Styling the label */
.form-group-resident .inbox_label {
  position: absolute;
  top: 1rem;
  left: 0.75rem;
  background: white;
  color: black;
  font-size: 1rem;
  padding: 0 0.25rem;
  transition: 0.3s ease all;
  pointer-events: none;
}

/* Move the label up when input is focused or has value */
.form-group-resident input:focus + .inbox_label,
.form-group-resident input:not(:placeholder-shown) + .inbox_label {
  top: -0.75rem;
  left: 1.5rem;
  font-size: 1rem;
  font-weight: 500;
  color: #2b2f32;
}

/* Styling the clear button (X) */
.form-group-resident input[type="text"]::-ms-clear {
  display: none;
}

.form-group-resident input[type="text"]::-webkit-clear-button {
  display: none;
}

/* Styling the select field */
.form-group-resident select {
  width: 100%;
  padding: 1rem 0.75rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  outline: none;
}
.user-response{
  color:  black !important;
}
.admin-response{
  color: white !important;
}
/* Styling the select label */
.form-group-resident .inbox_label {
  position: absolute;
  top: 1rem;
  left: 0.75rem;
  background: white;
  color: #2b2f32;
  font-size: 1rem;
  padding: 0 0.25rem;
  transition: 0.3s ease all;
  pointer-events: none;
}

/* Move the label up when select is focused or has value */
.form-group-resident select:focus + .inbox_label,
.form-group-resident select:not(:placeholder-shown) + .inbox_label {
  top: -0.75rem;
  left: 1.5rem;
  font-size: 1rem;
  font-weight: 500;
  color: #2b2f32 !important;
  background: transparent !important;
}

/* Styling the clear button (X) */
.form-group-resident select[type="text"]::-ms-clear {
  display: none;
}

.form-group-resident select[type="text"]::-webkit-clear-button {
  display: none;
}

/* Styling the textarea field */
.form-group-resident textarea {
  width: 100%;
  padding: 1rem 0.75rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  outline: none;
  background: transparent !important;
}

/* Styling the textarea label */
.form-group-resident .inbox_label {
  position: absolute;
  top: -1rem;
  left: 1.5rem;
  background: white;
  color: red;
  font-size: 1rem;
  padding: 0 0.25rem;
  transition: 0.3s ease all;
  pointer-events: none;
}

/* Move the label up when textarea is focused or has value */
.form-group-resident textarea:focus + .inbox_label,
/* .form-group-resident textarea:not(:placeholder-shown) + .inbox_label {
 top: -0.75rem;
  left: 1.5rem;
  font-size: 1rem;
  font-weight: 400;
  color: #2b2f32 !important;
  background: transparent !important;
} */

/* Styling the clear button (X) */
.form-group-resident textarea[type="text"]::-ms-clear {
  display: none;
}

.form-group-resident textarea[type="text"]::-webkit-clear-button {
  display: none;
}
.select__control.css-13cymwt-control {
  background: transparent;
  color: #fff !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.input-range,
.input-range:hover {
  accent-color: #003978 !important;
}

.form-group-resident .dark-label {
  background: #00254f !important;
  color: #fff !important;
}
.form-group-resident .dark-contant {
  color: #fff !important;
  background: transparent !important;
}
/*form css end*/
.dark-contant select option::before > ::after {
  background: transparent !important;
}
.custom-table-header tr th {
  background-color: #f8b322;
}
table {
  border-spacing: 0;
  border-collapse: separate;
  border-radius: 10px;
}
thead th:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 0;
}
thead th:last-child {
  border-top-right-radius: 4px;
  border-bottom-left-radius: 0;
}
.custom-pagination-option {
  background-color: #003978;
  color: white;
}

.sticky-sidebar-desktop {
  position: fixed !important;
  width: inherit;
  overflow-y: scroll !important;
  inset: 0;
  top: 190px;
  bottom: 40px;
}
@media only screen and (max-width: 600px)
{
  .sticky-sidebar-desktop {
    position: fixed;
    width: inherit;
    overflow-y: scroll !important;
    inset: 0;
    bottom: 40px !important;
  }
  button.storey-btn.active {
    background: #003978 !important;
    color: #ffff !important;
  }

}

.top-16 {
  top: 16px !important;
  /* border-bottom: 1px solid #ffffff6b !important; */
}
::-webkit-scrollbar {
  height: 100%;
  width: 4px;
  background: #003878e0;
  border-radius: 10px;
  margin-top: 2px;
}
::-webkit-scrollbar-track {
  width: 2px;
  box-shadow: inset 0 0 5px 005fcc;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  width: 2px;
  background: #0066dabd;
  border-radius: 4px;
}
::-webkit-scrollbar-thumb:hover {
  width: 2px;
  background: #666669;
}
.mt-n16 {
  margin-top: -16px;
}
.float-left {
  float: left;
}
.custom-swal-popup {
  width: 300px;
}
.custom-swal-popup-save {
  width: 350px;
}
.h-56 {
  height: 56px !important;
}
.truncate {
  -webkit-line-clamp: 3;
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
}

.overlay {
  position: absolute;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  transition: 0.5s ease;
  opacity: 0;
  color: white;
  font-size: 20px;
  padding-top: 30%;
  text-align: center;
}
.event-img:hover .overlay {
  opacity: 2;
}
.h-250 {
  height: 250px;
}
.input-group-append .btn {
  z-index: 5 !important;
}
.input-group-append .btn:hover {
  color: #707070 !important;
}
.react-datepicker-popper.hidden-picker {
  left: -14px !important;
}
.react-datepicker__year-option, 
.react-datepicker__year-option--selected_year, 
.react-datepicker__month-option, 
.react-datepicker__month-option--selected_month {
  color: #0B2447 !important;
}
@media only screen and (min-width: 1025px) {
 /*  .react-datepicker-popper.hidden-picker {
    position: absolute !important;
    left: 386px !important;
    top: 160px !important;
    transform: translate(-1170.4px, -508.8px) !important;
  } */
}

/* @media only screen and (min-width:320px) and (max-width:667px) {
  .react-datepicker-popper {
    position: absolute;
    left: 148px !important;
    top: 140px !important;
    transform: translate(-141px, -648.667px) !important;
    will-change: transform !important;
  }
}
@media only screen and (min-width:768px){
  .react-datepicker-popper{
      position: absolute;
      top: 290px !important;
      left: -235px !important;
      transform: translate(-141px, -648.667px) !important;
      will-change: transform !important;
  }
}

@media only screen and (min-width:820px) and (max-width:1194px){
  .react-datepicker-popper{
      position: absolute;
      top: 300px !important;
      left: -272px !important;
      transform: translate(-141px, -648.667px) !important;
      will-change: transform !important;
  }
} */
.pagination
{
  position: sticky;
}
.pagination-button-size{
  width: 32px;
}
.dropdown-container {
  position: relative;
}

.dropdown-menu {
  position: absolute;
  bottom: 100%; /* Position above the "Other" button */
  left: 80%;
  transform: translateX(-50%);
  background-color: white;
  border: 1px solid #ccc;
  display: flex;
  flex-direction: column;
  z-index: 1000; /* Ensure it appears above other elements */
}

.dropdown-menu button {
  padding: 10px;
  background: none;
  border: none;
  cursor: pointer;
}

.dropdown-menu button:hover {
  background-color: #f1f1f1;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
  border-radius: 0.3rem;
  background-color: #216ba5 !important;
  color: #fff;
}
.image {
  transition: filter 0.3s ease;
}

.image:hover {
  filter: invert(100%) brightness(100%);
}






@media only screen and (max-width: 768px) 
{

  .mobile-btn {
    margin:10px 0 0 0 !important;
  }

  #menu {
    position: fixed;
    top: 0;
    right: 0;
    width: 300px;
    height: 100%;
    background: #003978  !important;
  }
  
  #menu ul {
    margin: 0;
    padding: 0;
  }
  
  #menu ul li {
    margin: 0 10px;
    list-style: none;
    display: block;
    line-height: 30px;
    border-bottom: 1px solid #00254f;
  }
  
  #menu ul li.active {
    background: #184b85 !important;
  }
  
  #menu ul li a {
    padding: 2px 0 !important;
    color: #FFF !important;
    font-size: 14px !important;
    font-weight: bold !important;
    text-transform: uppercase !important;
    display: block !important;
    text-decoration: none !important;
    -webkit-transition-property: all !important;
    -webkit-transition-duration: 0.1s !important;
    -webkit-transition-timing-function: ease-out !important;
    -moz-transition-property: all !important;
    -moz-transition-duration: 0.1s !important;
    -moz-transition-timing-function: ease-out !important;
    -ms-transition-property: all !important;
    -ms-transition-duration: 0.1s !important;
    -ms-transition-timing-function: ease-out !important;
    -o-transition-property: all !important;
    -o-transition-duration: 0.1s !important;
    -o-transition-timing-function: ease-out !important;
    transition-property: all !important;
    transition-duration: 0.1s !important;
    transition-timing-function: ease-out !important;
  }
  
  #menu ul li:hover {
    border-bottom: 1px solid #000;
  }
  
  #menu ul li a:hover {
    color: #000;
  }
  
  .menu_icon {
    position: fixed;
    right: 20px;
    top: 20px;
    width: 30px;
    height: 18px;
  }
  
  .mobile-view h1,
  .mobile-view p {
    color: #003978;
  }

  .mobile-view h1,
  .mobile-view p:hover {
    color: #003978 !important;
  }
  
  .mobile-view .header {
    display: block;
    height: 50px;
  }
  
  .mobile-view .header h1 {
    padding-left: 100px;
    margin: 0;
  }
  
  
  .flex-column.form-group.nav.position-fixed {
    bottom: 85px;
  }
  
  .right-menu-mobile
  {height: 745px !important;}
  

  
  #menu{
    text-decoration: none !important;
    -webkit-transition-property: all !important;
    -webkit-transition-duration: 500s !important;
    -webkit-transition-timing-function: ease-out !important;
    -moz-transition-property: all !important;
    -moz-transition-duration: 500s !important;
    -moz-transition-timing-function: ease-out !important;
    -ms-transition-property: all !important;
    -ms-transition-duration: 500s !important;
    -ms-transition-timing-function: ease-out !important;
    -o-transition-property: all !important;
    -o-transition-duration: 500s !important;
    -o-transition-timing-function: ease-out !important;
    transition-property: all !important;
    transition-duration: 500s !important;
    transition-timing-function: ease-out !important;
  }
  .side-bar-menu ul.nav.flex-column .sidebar-nav li
  {
    background-color:#003978 !important;
  }
  .side-bar-menu .nav.flex-column .sidebar-nav li:active
  {
    background-color:#003978 !important;
  }
  ul.nav.flex-column.sidebar-nav
  {
    background-color: #0c4a8b;
  }
  .mein-menu  ul li a.active {
    background-color: #184b85 !important;
  }

  .mein-menu  ul li.notification-bg 
  {
    background-color: #184b85 !important;
  }


}



@media (min-width: 768px) and (max-width: 1024px) {
  .mobile-btn {
    margin:5px 0 0 0 !important;
  }
}
@media (min-width: 768px) and (max-width: 1090px)
{
.bg-primery.mein-menu.sticky-sidebar-desktop {
    /* margin-top: 34px; */
    position: absolute;
    top: 250px;
}
}

.form-group-resident .picker .react-datepicker-wrapper {
  width: 100%;
}
/* ul.pagination li.page-item.disabled button{
  background: #f8b322 !important;
} */
ul.pagination li.page-item.active button{
  color: #f8b322 !important;
  border-color: #f8b322 !important;
  z-index: 2 !important;
}
ul.pagination li.page-item.active button:focus
{
  box-shadow:0 0 0 0.25rem rgba(248, 179, 34, 0.25)
}
/* .bxy-scroll
{
  margin-bottom: 22vh !important;
  overflow-x: auto !important;
} */

@media only screen and (max-width: 600px)
{
  /* .bxy-scroll{  
    margin-bottom: 22vh !important;
    overflow-x: auto !important;
  } */
  .swal2-container.swal2-center.swal2-backdrop-show {
    z-index: 2147483647;
  }
}

.swal2-container.swal2-center.swal2-backdrop-show {
  z-index: 2147483647;
}

/* .custom-scrolling {
  position: relative;
  margin:0 0 70px 0;
} */

.LeftSidbar-UpdatedBtn
{
width: 16.7% !important;
}

/* .leftpanel-bottom-updated {    
  position: fixed;
  bottom: 0;
  font-size: 14px;
    display: block;
  width: 128px!important;
}

@media only screen and (min-width: 1024px)
{

  .leftpanel-bottom-updated {    
    width: 170.66px!important;
  }}
  @media (min-width: 820px) and  (max-width: 1180px) 
  {
  
    .leftpanel-bottom-updated {    
      min-width: 136px!important;
      max-width: 196px!important;
      width: 100% !important;
    }}
    @media  (min-width: 1180px)
  {
  
    .leftpanel-bottom-updated {    
      width: 196px!important;
    }}
    @media  (min-width: 912px)
    {
    
      .leftpanel-bottom-updated {    
        width: 170px!important;
      }}
    
    @media  (min-width: 1366px)
  {
  
    .leftpanel-bottom-updated {    
      width: 227px!important;
    }} 
    @media  (min-width: 1368px)
    {
    
      .leftpanel-bottom-updated {    
        width: 228px!important;
      }}*/

select { border: solid 1px #000;color:#000;}