.page-title {
    font-size: 1.5rem; /* Adjust the font size as needed */
}
.cursor-pointer{
    cursor: pointer;
}
.page-link-prev{
    border-top-left-radius: var(--bs-pagination-border-radius);
    border-bottom-left-radius: var(--bs-pagination-border-radius);
}
.swal-container-section{
    margin-top: 40px !important;
}
.btn-group>.btn-check:checked+.btn, .btn-group>.btn-check:focus+.btn, .btn-group>.btn.active{
    z-index: auto !important;
}
.bg-primary-cls {
    --bs-bg-opacity: 1;
    background-color: #003978 !important;
}
@media (max-width: 767.98px){
    .layout-content-section{
        margin-top: 70px;
    }
}

/* new range slider design custom range slider....start..........*/
.slider-wrapper {
    /* position: relative; */
    position: sticky;
    width: 100%;
    margin-top: 10px;
}
  
.custom-slider {
  width: 100%;
  -webkit-appearance: none;
  appearance: none;
  height: 8px;
  accent-color: #003978; /* Apply custom accent color */
/*   background: linear-gradient(to right, #003978 var(--value, 40%), #ddd var(--value, 40%), #ddd 100%); */
  background: linear-gradient(to right, #003978 0%, #003978 var(--value, 40%), #ddd var(--value, 40%), #ddd 100%);
  border-radius: 5px;
  outline: none;
  padding: 0;
  margin: 0;
  position: relative;
  
}

.custom-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px; /* Bigger thumb size */
  height: 20px;
  background: #003978; /* Match thumb to accent color */
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  z-index: 2;
}

.custom-slider::-moz-range-thumb {
  width: 20px;
  height: 20px;
  background: #003978;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  z-index: 2;
}

  
  .step-marks {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    transform: translateY(-50%);
    height: 0;
    /* z-index: 1; */
  }
  
  .step-marks .dot {
    position: absolute;
    height: 11px;
    width: 11px;
    background-color: #a3a9af;
    border-radius: 50%;
    transform: translateX(-50%);
    top:-11px;
  }
  .step-marks .dot.active {
    background-color: #6184ab;
  }
  
  .dot.active_bkp {
    background-color: #0d6efd; /* Active dot in blue */
    width: 14px;  /* Bigger size for active dot */
    height: 14px;
  }
  .step-labels {
    position: absolute;
    top: 14%; /* Position labels below the slider */
    left: 0;
    width: 100%;
    transform: translateY(20px); /* Adjust as needed to move labels further down */
    display: flex;
    justify-content: space-between;
    z-index: 0;
  }
  
  .step-labels .label {
    position: absolute;
    transform: translateX(-50%);
    font-size: 14px;
  }
  /* Use this to create the filled background */
.custom-slider:before {
  content: '';
  position: absolute;
  height: 8px;
  background: #003978; /* Color for filled track */
  border-radius: 5px;
  top: 8px;
  left: 0;
  /* width: calc(var(--value) * 100%); */
  width: var(--value);
  z-index: 1;
}
  /* new range slider design custom range slider....end..........*/
  .react-datepicker-popper{
    z-index: 3 !important;
  }